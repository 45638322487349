import './App.css';
import HeaderSection from './sections/Header';

function App() {
  return (
    <div className="">
      <header className="">
        <HeaderSection />
      </header>
    </div>
  );
}

export default App;
